import { SpecificUserSubscriptionDataSource } from "../../datasources/specific-user-subscription/specific-user-subscription.datasource";
import { ISpecificUserSubscriptionDatasource } from "../../datasources/specific-user-subscription/specific-user-subscription.datasource.interface";
import { UserSubscriptionDto } from "../../dtos/user-subscription/user-subscription.dto";
import { ISpecificUserSubscriptionRepository } from "./specific-user-subscription.repository.interface";

export class SpecificUserSubscriptionRepository implements ISpecificUserSubscriptionRepository {
  constructor(
    private readonly _remoteDatasource: ISpecificUserSubscriptionDatasource = new SpecificUserSubscriptionDataSource()
  ) {}

  getSpecificUserSubscription(citizenId: number): Promise<UserSubscriptionDto> {
    return this._remoteDatasource.getSpecificUserSubscription(citizenId);
  }
}
