import { IJWTUserPayload } from "../../interfaces/JWTUserPayload";
import { questionnaireService } from "../questionnaire/questionnaire.service.instance";
import { IQuestionnaireService } from "../questionnaire/questionnaire.service.interface";
import { IAuthenticationService } from "./authentication.service.interface";
import { AuthenticationRepository } from "./repositories/authentication.repository";
import { IAuthenticationRepository } from "./repositories/authentication.repository.interface";

export class AuthenticationService implements IAuthenticationService {
  constructor(
    private readonly _authRepository: IAuthenticationRepository = new AuthenticationRepository(),
    private readonly _questionnaireService: IQuestionnaireService = questionnaireService
  ) {}

  async logoutAndResetStorage(): Promise<void> {
    try {
      this.resetAuthenticationTokenStore();
      await this.logout();
    } catch (error) {
      console.error("Logout error", (error as Error)?.stack);
    } finally {
      this.resetAuthenticationStorage();
    }
  }

  async logout(): Promise<void> {
    return this._authRepository.logout();
  }

  resetAuthenticationStorage(): void {
    this._authRepository.resetAuthenticationStorage();
  }

  resetAuthenticationTokenStore(): void {
    this._questionnaireService.unsubscribeAndClear();
    this._authRepository.emitAuthenticationTokenStore(undefined);
  }

  setAuthenticationTokenStore(accessToken: IJWTUserPayload): void {
    this._authRepository.emitAuthenticationTokenStore(accessToken);
  }
}
