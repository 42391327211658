import { useCallback, useEffect, useState } from "react";
import { useChatbotName } from "../../../components/Chatbot/hooks/use-chatbot-name/use-chatbot-name";
import { clientConstantsServiceInstance } from "../../../services/client-constants/client-constants.service.instance";
import { ReplaceSinglePlaceholderProps } from "../../../services/client-constants/client-constants.service.interface";
import { useCurrentUserSubscriptionTier } from "../../use-current-user-subscription-tier/use-current-user-subscription-tier";
import { useFormValues } from "../../useFormValues";
import { ClientConstantsReplacementsManager } from "./replacements-manager/replacements-manager";

const replacementsManager = new ClientConstantsReplacementsManager();

export function useClientConstantsReplacements() {
  const { Nome } = useFormValues();
  const chatbotName = useChatbotName();
  const { translationKeyForTier, currentUserTier } = useCurrentUserSubscriptionTier();

  const [replacements, setReplacements] = useState<ReplaceSinglePlaceholderProps[]>([]);

  const revalidateReplacements = useCallback(() => {
    const newReplacements = replacementsManager.getReplacements({
      chatbotName,
      userFirstName: Nome ?? "",
      currentUserTier,
      translationKeyForTier,
    });
    setReplacements(newReplacements);
  }, [chatbotName, Nome, currentUserTier, translationKeyForTier]);

  useEffect(
    function updateReplacementsInService() {
      clientConstantsServiceInstance.placeholdersReplacements = replacements;
    },
    [replacements]
  );

  useEffect(
    function updateReplacements() {
      revalidateReplacements();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [Nome, chatbotName, currentUserTier, translationKeyForTier]
  );

  return {
    replacements,
    revalidateReplacements,
  };
}
