import { localStorageSelectiveClean } from "../../../../utils/localStorageSelectiveClean";

import cookie from "js-cookie";
import { cache as swrCache } from "swr";
import { BONUSX_ACCESS_TOKEN } from "../../../../common/env";
import { IJWTUserPayload } from "../../../../interfaces/JWTUserPayload";
import { accessTokenSubject$ } from "../../stores/access-token/access-token.store";

export class LocalAuthenticationStorageDataSource {
  private readonly _COOKIE_DEFAULT_DOMAIN = new URL(window.location.href).hostname;

  resetAuthenticationStorage(): void {
    localStorageSelectiveClean();
    swrCache.clear();
    cookie.remove(BONUSX_ACCESS_TOKEN, { path: "/", domain: `.${this._COOKIE_DEFAULT_DOMAIN}` });
  }

  emitAuthenticationTokenStore(accessToken?: IJWTUserPayload): void {
    accessTokenSubject$.next(accessToken);
  }
}
