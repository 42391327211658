import { Expose } from "class-transformer";
import { ChatbotMessageSenderType } from "../dtos/session-message.dto";

export interface IChatbotMessage {
  id?: number;
  senderType: ChatbotMessageSenderType;
  text: string;
}

export class ChatbotMessage implements IChatbotMessage {
  @Expose()
  id?: number;

  @Expose()
  senderType!: ChatbotMessageSenderType;

  @Expose({ name: "message" })
  text!: string;
}
