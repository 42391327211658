import { Mapper } from "../../utils/mapper";
import { ExternalUrlSession } from "./entities/external-url-session.entity";
import { UserSubscription, UserSubscriptionDiscount } from "./entities/user-subscription.entity";
import { BONUSX_SUBSCRIPTION_FREQUENCIES } from "./enums/bonusx-subscription-frequencies.enum";
import { BONUSX_SUBSCRIPTION_TIERS } from "./enums/bonusx-subscription-tiers.enum";
import { UserSubscriptionRepository } from "./repositories/current-user-subscription/user-subscription.repository";
import { IUserSubscriptionRepository } from "./repositories/current-user-subscription/user-subscription.repository.interface";
import { SpecificUserSubscriptionRepository } from "./repositories/specific-user-subscription/specific-user-subscription.repository";
import { ISpecificUserSubscriptionRepository } from "./repositories/specific-user-subscription/specific-user-subscription.repository.interface";
import { IUserSubscriptionService } from "./user-subscription.service.interface";

export class UserSubscriptionService implements IUserSubscriptionService {
  constructor(
    private readonly _repository: IUserSubscriptionRepository = new UserSubscriptionRepository(),
    private readonly _adminRepository: ISpecificUserSubscriptionRepository = new SpecificUserSubscriptionRepository()
  ) {}

  async getCurrentUserSubscriptionTier(): Promise<BONUSX_SUBSCRIPTION_TIERS> {
    const defaultTier = BONUSX_SUBSCRIPTION_TIERS.FREE;

    try {
      const subscription = await this._repository.getCurrentUserSubscription();
      return subscription.subscriptionTier ?? defaultTier;
    } catch (error) {
      return defaultTier;
    }
  }

  async getSpecificUserSubscriptionTier(citizenId: number): Promise<BONUSX_SUBSCRIPTION_TIERS | undefined> {
    try {
      const subscription = await this._adminRepository.getSpecificUserSubscription(citizenId);
      return subscription?.subscriptionTier;
    } catch (error) {
      console.error("Failed to get specific user subscription tier", (error as Error)?.stack);

      return undefined;
    }
  }

  async getSubscriptionAccount(): Promise<UserSubscription> {
    try {
      const subscription = await this._repository.getCurrentUserSubscription();

      const response = Mapper.mapData(UserSubscription, subscription);
      response.discounts = Mapper.mapArrayData(UserSubscriptionDiscount, subscription.discounts);

      return response;
    } catch (error) {
      console.error("Failed to get current user subscription status (returning default)", (error as Error)?.stack);
      return this.getDefaultSubscriptionAccount();
    }
  }

  async getDefaultSubscriptionAccount(): Promise<UserSubscription> {
    const defaultSubscription = new UserSubscription();
    defaultSubscription.providerCode = BONUSX_SUBSCRIPTION_FREQUENCIES.YEARLY;
    defaultSubscription.subscriptionTier = BONUSX_SUBSCRIPTION_TIERS.FREE;
    return defaultSubscription;
  }

  async getSelfPortalPage(): Promise<string> {
    const selfPortalPage = await this._repository.getSelfPortalPage();

    return selfPortalPage?.url;
  }

  getRenewalFrequencyFromSubscriptionProviderCode(providerCode?: string): BONUSX_SUBSCRIPTION_FREQUENCIES {
    const defaultFrequency = BONUSX_SUBSCRIPTION_FREQUENCIES.YEARLY;
    if (!providerCode) {
      return defaultFrequency;
    }

    const lowerCaseSubscriptionId = providerCode.toLowerCase();
    const isMonthly = lowerCaseSubscriptionId.includes("monthly");

    if (isMonthly) {
      return BONUSX_SUBSCRIPTION_FREQUENCIES.MONTHLY;
    }

    return defaultFrequency;
  }

  async generateCheckoutPageForItem(item: string, redirectUrl: string): Promise<ExternalUrlSession> {
    const externalUrlSession = await this._repository.generateCheckoutPageForItem(item, redirectUrl);

    return externalUrlSession;
  }
}
