import { Subscription } from "rxjs";
import { IQuestionnaireService } from "./questionnaire.service.interface";

export class QuestionnaireService implements IQuestionnaireService {
  private _subscription: Subscription | undefined;

  bindSubscription(subscription: Subscription): void {
    this._subscription = subscription;
  }

  unsubscribeAndClear(): void {
    this._subscription?.unsubscribe();
    this._subscription = undefined;
  }
}
