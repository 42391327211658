import isUndefined from "lodash/isUndefined";
import lru from "quick-lru";
import { ClientConstantCmsDto } from "../../dtos/client-constant.dto";
import { IClientConstantCachedDatasource } from "./client-constants-cached.datasource.interface";

export const CLIENT_CONSTANTS_CACHE_MAX_SIZE = 1_000;

export class ClientConstantsCacheDatasource implements IClientConstantCachedDatasource {
  constructor(
    private readonly _cache = new lru<string, string>({
      maxSize: CLIENT_CONSTANTS_CACHE_MAX_SIZE,
    })
  ) {}

  getClientConstantValue(key: string): ClientConstantCmsDto | undefined {
    const cachedData = this._cache.get(key);

    if (isUndefined(cachedData)) {
      return undefined;
    }

    const clientConstantDto = new ClientConstantCmsDto();
    clientConstantDto.constantName = key;
    clientConstantDto.constantValue = cachedData;
    return clientConstantDto;
  }

  setClientConstantValue(clientConstant: ClientConstantCmsDto): void {
    this._cache.set(clientConstant.constantName, clientConstant.constantValue);
  }

  populateClientConstantsCache(clientConstants: ClientConstantCmsDto[]): void {
    for (const clientConstant of clientConstants) {
      this.setClientConstantValue(clientConstant);
    }
  }
}
