import { Mapper } from "../../../utils/mapper";
import { getDecodedUserAccessTokenFromBrowserCookies } from "../../authentication-service/stores/access-token/get-decoded-user-access-token-from-browser-cookies/get-decoded-user-access-token-from-browser-cookies";
import { FeatureFlagContextAccountData } from "./entity/feature-flag-context-account-data";
import { IFeatureFlagContextAccountData } from "./entity/feature-flag-context-account-data.interface";
import { IFeatureFlagContextAccountDataService } from "./feature-flag-context-account-data.interface";

export class FeatureFlagContextAccountDataService implements IFeatureFlagContextAccountDataService {
  constructor(private readonly _getUserToken = getDecodedUserAccessTokenFromBrowserCookies) {}
  extractFromToken(): IFeatureFlagContextAccountData {
    const contextAccountData = this._getUserDataFromAccessToken();

    return contextAccountData;
  }

  private _getUserDataFromAccessToken(): IFeatureFlagContextAccountData {
    const token = this._getUserToken();

    if (!token) {
      return new FeatureFlagContextAccountData();
    }

    const accountData = {
      accountId: token.account.id,
      targetingKey: token.account.id,
      name: token.account.name,
      surname: token.account.surname,
      email: token.account.email,
    };

    const contextAccountData = Mapper.mapData(FeatureFlagContextAccountData, accountData);

    return contextAccountData;
  }
}
