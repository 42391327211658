import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import lru from "quick-lru";
import {
  GetClientConstantValueOrDefaultProps,
  IClientConstantsService,
  ReplacePlaceholdersProps,
  ReplaceSinglePlaceholderProps,
} from "./client-constants.service.interface";
import { CLIENT_CONSTANTS_CACHE_MAX_SIZE } from "./datasources/cached/client-constants-cached.datasource";
import { ClientConstantsRepository } from "./repositories/client-constants.repository";
import { IClientConstantsRepository } from "./repositories/client-constants.repository.interface";

export class ClientConstantsService implements IClientConstantsService {
  private static _singletonInstance: ClientConstantsService;
  private _isInitialized = false;
  private _placeholdersReplacements: ReplaceSinglePlaceholderProps[] = [];

  constructor(
    private readonly _clientConstantsRepository: IClientConstantsRepository = new ClientConstantsRepository(),
    private readonly _cachedConstantsWithReplacements = new lru<string, string>({
      maxSize: CLIENT_CONSTANTS_CACHE_MAX_SIZE,
    })
  ) {}

  static get instance(): ClientConstantsService {
    if (!ClientConstantsService._singletonInstance) {
      ClientConstantsService._singletonInstance = new ClientConstantsService();
    }
    return ClientConstantsService._singletonInstance;
  }

  async initialize() {
    if (this._isInitialized) {
      return;
    }

    try {
      await this._clientConstantsRepository.fetchAllClientConstantsFromRemote();
      this._isInitialized = true;
    } catch (error) {
      this._isInitialized = false;
      return;
    }
  }

  set placeholdersReplacements(replacements: ReplaceSinglePlaceholderProps[]) {
    this._placeholdersReplacements = replacements;
    this._invalidateCachedConstantsWithAppliedReplacements();
  }

  _invalidateCachedConstantsWithAppliedReplacements() {
    this._cachedConstantsWithReplacements.clear();
  }

  getClientConstantValueOrDefault({
    key: constantKey,
    default: defaultValue,
  }: GetClientConstantValueOrDefaultProps): string {
    const cachedConstantWithReplacementsApplied = this._getClientConstantWithReplacementsFromCache(constantKey);
    if (!isUndefined(cachedConstantWithReplacementsApplied)) {
      return cachedConstantWithReplacementsApplied;
    }

    const cachedConstantWithoutReplacementsApplied = this._getClientConstantWithoutReplacementsFromCache(constantKey);
    if (isUndefined(cachedConstantWithoutReplacementsApplied)) {
      return this._applyReplacementsAndSaveToCache(constantKey, defaultValue);
    }

    return this._applyReplacementsAndSaveToCache(constantKey, cachedConstantWithoutReplacementsApplied);
  }

  private _applyReplacementsAndSaveToCache(constantKey: string, value: string): string {
    const valueWithReplacementsApplied = this.replacePlaceholders({
      originalString: value,
      replacements: this._placeholdersReplacements,
    });
    this._insertClientConstantWithAppliedReplacementsIntoCache(constantKey, valueWithReplacementsApplied);
    return valueWithReplacementsApplied;
  }

  replacePlaceholders({ originalString, replacements }: Readonly<ReplacePlaceholdersProps>): string {
    if (!replacements || isEmpty(replacements)) {
      return originalString;
    }

    const doesOriginalStringContainPlaceholders = this._doesStringContainPlaceholders(originalString);
    if (!doesOriginalStringContainPlaceholders) {
      return originalString;
    }

    let copiedValue = originalString.concat("");
    for (const replacement of replacements) {
      copiedValue = this._replaceSinglePlaceholder(copiedValue, {
        stringToReplace: replacement.stringToReplace,
        replaceWith: replacement.replaceWith,
      });
    }
    return copiedValue;
  }

  private _replaceSinglePlaceholder(
    originalString: Readonly<string>,
    { stringToReplace, replaceWith }: Readonly<ReplaceSinglePlaceholderProps>
  ): string {
    let updatedString = originalString;
    const replaceRegExp = new RegExp(stringToReplace, "g");
    updatedString = updatedString?.replace(replaceRegExp, replaceWith);
    return updatedString;
  }

  private _getClientConstantWithReplacementsFromCache(constantKey: string): string | undefined {
    const cachedConstantWithAppliedReplacements = this._cachedConstantsWithReplacements.get(constantKey);
    if (!isUndefined(cachedConstantWithAppliedReplacements)) {
      return cachedConstantWithAppliedReplacements;
    }
    return undefined;
  }

  private _getClientConstantWithoutReplacementsFromCache(constantKey: string): string | undefined {
    const cachedConstantWithoutAppliedReplacements = this._clientConstantsRepository.getValueFromCache(constantKey);
    return cachedConstantWithoutAppliedReplacements?.constantValue;
  }

  private _insertClientConstantWithAppliedReplacementsIntoCache(constantKey: string, computedValue: string) {
    this._cachedConstantsWithReplacements.set(constantKey, computedValue);
  }

  private _doesStringContainPlaceholders(stringToCheck: string): boolean {
    const atLeastOnePlaceholderRegex = /%\w+%/;
    return atLeastOnePlaceholderRegex.test(stringToCheck);
  }
}
