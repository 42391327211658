import { useEffect } from "react";
import { HttpStatusCode } from "../../../common/http-status-code";
import { useLogout } from "../use-logout";

export default function useLogoutWhenUnauthorized(shouldLogout: boolean, error: any) {
  const { logout } = useLogout();

  useEffect(
    function handleAuthenticationError() {
      if (shouldLogout && error && error.response?.status === HttpStatusCode.UNAUTHORIZED) {
        logout()
          .catch((logoutError) => {
            console.error("Error during logout on handleAuthenticationError", (logoutError as Error)?.stack);
          })
          .finally(() => {
            window.location.href = "/welcome";
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldLogout, error]
  );
}
