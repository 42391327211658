import { useAtomValue } from "jotai";
import { useEffect } from "react";
import { beforeUnloadEventName } from "../common/env";
import { accessTokenStore } from "../services/authentication-service/stores/access-token/access-token.store";
import { CanEscapeRefStore } from "../stores/app";

const beforeUnloadAction = (event: Event) => {
  const confirmationMessage = "o/";

  //@ts-ignore (in teoria returnValue è deprecato)
  event.returnValue = confirmationMessage; //Gecko + IE
  return confirmationMessage; //Webkit, Safari, Chrome
};

export const WindowBeforeUnload: React.FC = () => {
  const canEscapeRef = useAtomValue(CanEscapeRefStore);
  const accessToken = useAtomValue(accessTokenStore);

  useEffect(() => {
    if (!accessToken) return window.removeEventListener(beforeUnloadEventName, beforeUnloadAction);
    if (!canEscapeRef) return window.removeEventListener(beforeUnloadEventName, beforeUnloadAction);

    window.addEventListener(beforeUnloadEventName, beforeUnloadAction);
    return () => window.removeEventListener(beforeUnloadEventName, beforeUnloadAction);
  }, [canEscapeRef, accessToken]);

  return <></>;
};
