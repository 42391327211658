import { AxiosInstance, AxiosStatic } from "axios";
import { IApiClientRequestConfigOptions } from "../ApiClient/ApiClientRequestConfigOptions.interface";
import { IApiClientService } from "../ApiClient/ApiClientService.interface";
import axiosInstance from "../axios-config-singleton/axios-config-singleton.instance";
import { IApiClientConfig } from "./dtos/AxiosApiClientConfig";
import { AxiosApiClientResponse, IApiClientResponse } from "./dtos/AxiosApiClientResponse";

export class AxiosApiClientInstance implements IApiClientService {
  private _clientInstance: AxiosInstance;

  constructor(client: AxiosStatic = axiosInstance, config?: IApiClientConfig) {
    this._clientInstance = client.create(config);
  }

  public async get<Data>(url: string, options: IApiClientRequestConfigOptions): Promise<IApiClientResponse<Data>> {
    const responseFromAxios = await this._clientInstance.get<Data>(url, options);
    const response = new AxiosApiClientResponse<Data>(responseFromAxios);

    return response;
  }

  public async post<Data, ResponseData>(
    url: string,
    body: Data,
    options: IApiClientRequestConfigOptions
  ): Promise<IApiClientResponse<ResponseData>> {
    const responseFromAxios = await this._clientInstance.post<ResponseData>(url, body, options);
    const response = new AxiosApiClientResponse<ResponseData>(responseFromAxios);

    return response;
  }

  public async patch<Data, ResponseData>(
    url: string,
    body: Data,
    options: IApiClientRequestConfigOptions
  ): Promise<IApiClientResponse<ResponseData>> {
    const responseFromAxios = await this._clientInstance.patch<ResponseData>(url, body, options);
    const response = new AxiosApiClientResponse<ResponseData>(responseFromAxios);

    return response;
  }
}

export default AxiosApiClientInstance;
