import { AI_CHATBOT_ACTIONS } from "../enums/chatbot.reducer.enum";
import { ChatbotAction, ChatbotState } from "../types/chatbot.reducer.type";

export function subscribtionReducer(state: ChatbotState, action: ChatbotAction) {
  switch (action.type) {
    case AI_CHATBOT_ACTIONS.SET_USER_SUBSCRIPTION_TIER:
      return {
        ...state,
        userSubscriptionTier: action.subscriptionTierValue,
      };

    default:
      return {};
  }
}
