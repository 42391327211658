import { HttpStatusCode } from "../../../../common/http-status-code";
import { getAxiosPyrgosConfig } from "../../../../common/pyrgos";
import { IApiClientService } from "../../../../services/ApiClient/ApiClientService.interface";
import AxiosApiClientInstance from "../../../../services/AxiosApiClientInstance/AxiosApiClientInstance";
import { Mapper } from "../../../../utils/mapper";
import { ConversationDTO, IConversationDTO } from "../../dtos/conversation.dto";
import { ISessionMessageDTO, SessionMessageDTO } from "../../dtos/session-message.dto";
import { ISessionDTO, SessionDTO } from "../../dtos/session.dto";
import { IConversationRemoteDataSource } from "./conversation-remote.datasource.interface";

export class ConversationRemoteDataSource implements IConversationRemoteDataSource {
  private readonly _OPTIONS = getAxiosPyrgosConfig();
  private readonly _BASE_URL = "/conversations";

  constructor(private readonly _client: IApiClientService = new AxiosApiClientInstance()) {}

  public async createNewSession(): Promise<ISessionDTO> {
    const { data } = await this._client.post<null, SessionDTO>(`${this._BASE_URL}/`, null, this._OPTIONS);

    if (!data) {
      throw new Error("Error while creating new session");
    }

    const sessionDto = Mapper.mapData(SessionDTO, data);
    return sessionDto;
  }

  public async getLastSession(): Promise<ISessionDTO | void> {
    const { data, status } = await this._client.get<SessionDTO | void>(
      `${this._BASE_URL}/get-last-session`,
      this._OPTIONS
    );

    const isNoContentResponse = status === HttpStatusCode.NO_CONTENT;
    if (isNoContentResponse) {
      return;
    }

    if (!data) {
      throw new Error("Error while retrieving last session");
    }

    const sessionDto = Mapper.mapData(SessionDTO, data);
    return sessionDto;
  }

  public async getAllMessagesForSession(sessionId: string): Promise<ISessionMessageDTO[]> {
    const { data } = await this._client.get<ISessionMessageDTO[]>(`${this._BASE_URL}/${sessionId}/messages`, {
      ...this._OPTIONS,
      params: {
        order: "ASC",
      },
    });

    if (!data) {
      throw new Error("No session messages available");
    }

    const messagesForSession = Mapper.mapArrayData(SessionMessageDTO, data);
    return messagesForSession;
  }

  public async getAllConversations(): Promise<IConversationDTO[]> {
    const { data } = await this._client.get<IConversationDTO[]>(`${this._BASE_URL}`, this._OPTIONS);

    if (!data) {
      throw new Error("No conversations available");
    }

    const conversations = Mapper.mapArrayData(ConversationDTO, data);
    return conversations;
  }

  public async cloneFromPreviousChat(): Promise<ISessionDTO> {
    const { data } = await this._client.post<null, ISessionDTO>(
      `${this._BASE_URL}/clone-messages-from-previous-session`,
      null,
      this._OPTIONS
    );

    if (!data) {
      throw new Error("No previous session available");
    }

    const sessionDto = Mapper.mapData(SessionDTO, data);
    return sessionDto;
  }
}
