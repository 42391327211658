import { Mapper } from "../../../../utils/mapper";
import { UserSubscriptionDataSource } from "../../datasources/current-user-subscription/user-subscription.datasource";
import { IUserSubscriptionDatasource } from "../../datasources/current-user-subscription/user-subscription.datasource.interface";
import { UserSubscriptionDto } from "../../dtos/user-subscription/user-subscription.dto";
import { ExternalUrlSession } from "../../entities/external-url-session.entity";
import { SelfPortalPageEntity } from "../../entities/self-portal-page.entity";
import { IUserSubscriptionRepository } from "./user-subscription.repository.interface";

export class UserSubscriptionRepository implements IUserSubscriptionRepository {
  constructor(private readonly _remoteDatasource: IUserSubscriptionDatasource = new UserSubscriptionDataSource()) {}

  getCurrentUserSubscription(): Promise<UserSubscriptionDto> {
    return this._remoteDatasource.getCurrentUserSubscription();
  }

  async getSelfPortalPage(): Promise<SelfPortalPageEntity> {
    const selfPortalPageDto = await this._remoteDatasource.getSelfPortalPage();
    const selfPortalPageEntity = Mapper.mapData(SelfPortalPageEntity, selfPortalPageDto);

    return selfPortalPageEntity;
  }

  async generateCheckoutPageForItem(item: string, redirectUrl: string): Promise<ExternalUrlSession> {
    const externalUrlSessionDto = await this._remoteDatasource.generateCheckoutPageForItem(item, redirectUrl);

    const externalUrlSession = Mapper.mapData(ExternalUrlSession, externalUrlSessionDto);

    return externalUrlSession;
  }
}
