import { useCallback } from "react";
import useSWR from "swr";
import { swrBaseConfig } from "../../common/swrBaseConfig";
import { UserSubscription } from "../../services/user-subscription/entities/user-subscription.entity";
import { BONUSX_SUBSCRIPTION_TIERS } from "../../services/user-subscription/enums/bonusx-subscription-tiers.enum";
import { userSubscriptionService } from "../../services/user-subscription/user-subscription.instance";
import { useIsLogged } from "../use-is-logged/use-is-logged";
import { getTranslationKeyForSubscriptionTier } from "./use-current-user-subscription-tier.functions";
import { UseCurrentUserSubscriptionTierOutput } from "./use-current-user-subscription-tier.interface";

const USER_SUBSCRIPTION_TIER_KEY = "current-user-subscription-tier";
const USER_SUBSCRIPTION_TIER_CACHE_SECONDS = 2;
const USER_SUBSCRIPTION_TIER_CACHE_MILLISECONDS = USER_SUBSCRIPTION_TIER_CACHE_SECONDS * 1000;

export function useCurrentUserSubscriptionTier(): UseCurrentUserSubscriptionTierOutput {
  const isLogged = useIsLogged();

  const fetcherFunction = useCallback(
    function subscriptionTierFetcherFunction(): Promise<UserSubscription> {
      if (isLogged) {
        return userSubscriptionService.getSubscriptionAccount();
      }
      return userSubscriptionService.getDefaultSubscriptionAccount();
    },
    [isLogged]
  );

  const cacheKeyBasedOnUserLoginStatus = [USER_SUBSCRIPTION_TIER_KEY, isLogged];
  const currentUserTierData = useSWR<UserSubscription>(cacheKeyBasedOnUserLoginStatus, fetcherFunction, {
    ...swrBaseConfig,
    dedupingInterval: USER_SUBSCRIPTION_TIER_CACHE_MILLISECONDS,
    suspense: true,
  });

  const currentUserTierOrFree = currentUserTierData.data?.subscriptionTier ?? BONUSX_SUBSCRIPTION_TIERS.FREE;
  const isLoading = currentUserTierData.isValidating;
  const revalidate = currentUserTierData.revalidate;

  const currentUserTierTranslationKey = getTranslationKeyForSubscriptionTier(currentUserTierOrFree);
  const currentUserTierRenewalFrequency = userSubscriptionService.getRenewalFrequencyFromSubscriptionProviderCode(
    currentUserTierData.data?.providerCode
  );

  const currentUserTierStartDate = currentUserTierData.data?.startDate;
  const currentUserTierEndDate = currentUserTierData.data?.endDate;

  return {
    currentUserTier: currentUserTierOrFree,
    translationKeyForTier: currentUserTierTranslationKey,
    currentUserTierFrequency: currentUserTierRenewalFrequency,
    discounts: currentUserTierData.data?.discounts ?? [],
    currentUserTierStartDate,
    currentUserTierEndDate,
    isLoading,
    revalidate,
  };
}
