import { atomWithObservable } from "jotai/utils";
import { Subject } from "rxjs";
import { IJWTUserPayload } from "../../../../interfaces/JWTUserPayload";
import { getDecodedUserAccessTokenFromBrowserCookies } from "./get-decoded-user-access-token-from-browser-cookies/get-decoded-user-access-token-from-browser-cookies";

export const accessTokenSubject$ = new Subject<IJWTUserPayload | undefined>();

export const accessTokenStore = atomWithObservable(() => accessTokenSubject$, {
  initialValue: getDecodedUserAccessTokenFromBrowserCookies,
});
