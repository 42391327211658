import { Expose } from "class-transformer";
import { BONUSX_SUBSCRIPTION_TIERS } from "../enums/bonusx-subscription-tiers.enum";
import { DISCOUNT_UNIT } from "../enums/discount-unit.enum";

export class UserSubscriptionDiscount {
  @Expose({
    name: "discount",
  })
  amount!: number;

  @Expose({
    name: "discountUnit",
  })
  unit!: DISCOUNT_UNIT;

  @Expose({
    name: "item",
  })
  item!: string;
}

export class UserSubscription {
  @Expose()
  id!: number;

  @Expose()
  citizenId!: number;

  @Expose()
  subscriptionId!: string;

  @Expose()
  subscriptionTier!: BONUSX_SUBSCRIPTION_TIERS;

  @Expose()
  providerCode!: string;

  @Expose()
  providerCustomerId!: string;

  @Expose()
  startDate!: Date;

  @Expose()
  endDate!: Date;

  @Expose()
  createdAt!: Date;

  @Expose()
  updatedAt!: Date;

  @Expose()
  amount!: number;

  @Expose()
  amountPaid!: number | null;

  @Expose()
  discounts: UserSubscriptionDiscount[] = [];
}
