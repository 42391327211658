import axios, { AxiosStatic } from "axios";
import { AxiosInterceptorAuthorization } from "../axios-interceptor-authorization/axios-interceptor-authorization";
import { IAxiosInterceptorAuthorization } from "../axios-interceptor-authorization/axios-interceptor-authorization.interface";
import { IAxiosConfigSingleton } from "./axios-config-singleton.interface";

export class AxiosConfigSingleton implements IAxiosConfigSingleton {
  static instance: AxiosConfigSingleton;

  constructor(
    private readonly _client: AxiosStatic = axios,
    private readonly _interceptorAuthorization: IAxiosInterceptorAuthorization = new AxiosInterceptorAuthorization()
  ) {
    this._client = _client;

    const onFulfilled = this._interceptorAuthorization.onFulfilled.bind(this._interceptorAuthorization);
    const onRejected = this._interceptorAuthorization.onRejected.bind(this._interceptorAuthorization);

    this._client.interceptors.response.use(onFulfilled, onRejected);
  }

  static getInstance(): AxiosConfigSingleton {
    if (!AxiosConfigSingleton.instance) {
      AxiosConfigSingleton.instance = new AxiosConfigSingleton();
    }

    const instance = AxiosConfigSingleton.instance;
    return instance;
  }

  public getSingletonClient(): AxiosStatic {
    return this._client;
  }

  static getClient(): AxiosStatic {
    const instance = AxiosConfigSingleton.getInstance();
    const client = instance.getSingletonClient();
    return client;
  }
}
