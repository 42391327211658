import axios, { AxiosInstance } from "axios";
import { getBonusxAuthConfigV1 } from "../../../../common/bonusxAuthConfig";

import { IAuthenticationDataSource } from "./authentication.datasource.interface";

export class AuthenticationDataSource implements IAuthenticationDataSource {
  private readonly _LOGOUT_URL = "/authentication/logout";
  private readonly _CLIENT_OPTIONS = getBonusxAuthConfigV1();

  // Note: this datasource directly instantiates an axios instance, not using the singleton we have, since this is a special case (logout)
  constructor(private readonly _client: AxiosInstance = axios.create()) {}

  async logout(): Promise<void> {
    await this._client.get<void>(this._LOGOUT_URL, this._CLIENT_OPTIONS);
  }
}
