import { AxiosError, AxiosRequestConfig } from "axios";
import { HttpStatusCode } from "../../common/http-status-code";
import { AuthenticationService } from "../authentication-service/authentication.service";
import { IAuthenticationService } from "../authentication-service/authentication.service.interface";
import { IAxiosInterceptorAuthorization } from "./axios-interceptor-authorization.interface";

export class AxiosInterceptorAuthorization implements IAxiosInterceptorAuthorization {
  constructor(private readonly _logoutService: IAuthenticationService = new AuthenticationService()) {}

  async onFulfilled(value: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    return value;
  }

  async onRejected(error: AxiosError): Promise<unknown> {
    const isUnauthorized = error?.response?.status === HttpStatusCode.UNAUTHORIZED;

    if (isUnauthorized) {
      await this._logoutService.logoutAndResetStorage();
    }

    return error;
  }
}
