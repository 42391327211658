import { APP_BAR_HEIGHT_DESKTOP, APP_BAR_HEIGHT_MOBILE } from "@bonusx-global/bx-design-system";
import * as rdd from "react-device-detect";
import { localization } from "./localization";
import { hexWithOpacity } from "./utils/hexWithOpacity";

const R = {
  colors: {
    white: "#FFFFFF",
    whiteTeeth: "#EBE9F1",
    lightGray: "#F7F7F7",
    mediumGray: "#E7E7E7",
    disabledGray: "#D6D6D6",
    smoothBlack: "#646874",
    black: "rgba(0, 0, 0, .6)",
    secondaryBlue: "#295BE0",
    vividBlue: "#1246C8",
    vividGreen: "#1AD68A",
    secondaryGreen: "#60E2AA",
    vividRed: "#FB3D75",
    secondaryRed: "#FF4A7B",
    errorRed: "#CC0000",
    primaryOrange: "#EB6144",
    secondaryOrange: "#FF8B4A",
    gradientLightBlue: "rgba(131, 165, 239, 0.15)",

    //  GRADIENT'S COLORS
    deepBlue: "#2C3346", // targetta info servizi
    darkBlue: "#1D3167", // targetta info servizi
    gradientColorPrimaryOne: "#1A59E7",
    gradientColorPrimarySecond: "#0066FF",
    gradientColorGreenPrimary: "#00DA82",
    gradientColorGreenSecondary: "#00DAC0",
    gradientColorPinkSecondary: "#F31C5C",
    //  -------------------------------

    //  ATTENDERE FIGMA ADMIN
    mediumSeaGreen: "rgba(0, 218, 130, 0.3)", //  admin
    superLightBlue: "#00CFE8", // admin
    aqua: "#A9EAD0",
    superRed: "#ff1744", // admin - shoppingCart
    yellow: "#FDEB7B",
    paypalYellow: "#FFC43A",
    royalBlue: "rgba(131, 165, 239, 0.30)",
    // --------------------------------

    border: hexWithOpacity("#094074", 0.12),
  },
  dimens: {
    appbarHeight: {
      default: 72,
      mobile: 56,
      mobileExtended: 112,
    },
    drawerWidth: 356,
    drawerUserWidth: "16.25rem",
    ellipseMask: 388,
    sideWidth: {
      large: 456,
      strict: 380,
    },
  },
  strings: localization,
};

const genericSizes = {
  xs: "0.3rem",
  small: "0.5rem",
  mediumSmall: "0.625rem",
  medium: "1rem",
  mediumLarge: "1.25rem",
  large: "1.5rem",
  xl: "2rem",
  xxl: "3rem",
};

export const size = {
  padding: { ...genericSizes },
  margin: { ...genericSizes },
  radius: { ...genericSizes },
  gap: { ...genericSizes },
  fontSize: {
    small: "0.625rem",
    mediumSmall: "0.75rem",
    medium: "0.875rem",
    mediumLarge: "1rem",
    large: "1.125rem",
    moreLarge: "1.25rem",
    extraLarge: "1.5rem",
    megaExtraLarge: "2.25rem",
  },
  button: {
    width: {
      mobile: "16.875rem",
      desktop: "20.625rem",
    },
  },
  field: {
    height: {
      extraSmall: "2.5rem",
      small: "2.813rem",
    },
    width: {
      mobile: "16.875rem",
      desktop: "100%",
    },
  },
};

export const font = {
  fontFamily: "Sora",
};

export const weight = {
  fontWeight: { regular: 400, bold: 600 },
};

export const typography = {
  lineHeight: { small: "1rem", medium: "2rem", large: "3rem" },
};

export const headerWidth = "62.125rem";

export const headerHeight = rdd.isMobile ? APP_BAR_HEIGHT_MOBILE : APP_BAR_HEIGHT_DESKTOP;

export const containerWidthCollection = new Map([
  ["default", headerWidth],
  ["siderbar", "15.625rem"],
]);

// TODO: find the right naming
export enum zIndexes {
  one = 1,
  one_thousand = 1000,
  one_thousand_plus_one = 1001,
  one_thousand_minus_one = 999,
}

export default R;
