import isEqual from "lodash/isEqual";
import { createStore } from "redux";
import { from, Subject } from "rxjs";
import { distinctUntilChanged, switchMap, throttleTime } from "rxjs/operators";
import rootReducer from "./reducers";
import { questionnaireService } from "./services/questionnaire/questionnaire.service.instance";
import { handleQuestionnaire } from "./stores/questionnaire/handleQuestionnaire";
import { questionnaireSubject$ } from "./stores/questionnaireSubject";
import { serializeForDiffCheck } from "./utils/serializeForDiffCheck";

export const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const questionnairePersist$ = questionnaireSubject$.pipe(
  throttleTime(500, undefined, { trailing: true }),
  distinctUntilChanged(function isDiffState(previous, current) {
    const isTheSame = isEqual(serializeForDiffCheck(previous.formValues), serializeForDiffCheck(current.formValues));
    return isTheSame;
  }),
  switchMap((data) => {
    return from(handleQuestionnaire(data));
  })
);

export function subscribeToQuestionnairePersist(questionnaireSubject$: Subject<RootStoreModule.State>) {
  questionnaireService.unsubscribeAndClear();

  const subscription = questionnairePersist$.subscribe({
    next: function onResponseReceived(data) {
      console.debug("Questionnaire response:", data);
    },
    error: function onError(error) {
      console.error("Questionnaire error:", error);
      subscribeToQuestionnairePersist(questionnaireSubject$);
    },
    complete: function onComplete() {
      console.debug("Questionnaire stop listen");
      subscribeToQuestionnairePersist(questionnaireSubject$);
    },
  });

  questionnaireService.bindSubscription(subscription);
}

subscribeToQuestionnairePersist(questionnaireSubject$);

store.subscribe(function onSetQuestionnaire() {
  const currentState = store.getState().updates;

  questionnaireSubject$.next(currentState);
});

export default store;
