import { AI_CHATBOT_ACTIONS } from "../enums/chatbot.reducer.enum";
import { ChatbotAction, ChatbotState } from "../types/chatbot.reducer.type";
import { appendStreamChunkToAiResponse } from "../utils/append-stream-chunk-to-ai-response";

export function messagesReducer(state: ChatbotState, action: ChatbotAction) {
  switch (action.type) {
    case AI_CHATBOT_ACTIONS.POPULATE_CHAT_MESSAGES:
      return {
        ...state,
        messages: action.messagesArrayPayloadValue ?? [],
      };

    case AI_CHATBOT_ACTIONS.ADD_USER_MESSAGE_ACTION:
    case AI_CHATBOT_ACTIONS.ADD_AI_RESPONSE_ACTION:
      return {
        ...state,
        messages: [...state.messages, action.messagePayloadValue],
      };
    case AI_CHATBOT_ACTIONS.CLEAR_CHAT_MESSAGES:
      return {
        ...state,
        messages: [],
      };
    case AI_CHATBOT_ACTIONS.ADD_AI_RESPONSE_STREAM_ACTION:
      if (action.messagePayloadValue) {
        return {
          ...state,
          messages: appendStreamChunkToAiResponse(state.messages, action.messagePayloadValue),
        };
      }
      return state;

    default:
      return {};
  }
}
