import { useCallback, useMemo, useReducer } from "react";
import useLogoutWhenUnauthorized from "../../../hooks/use-logout/use-logout-when-unauthorized/use-logout-when-unauthorized";
import { ErrorHttpContext } from "../ErrorHttpContext";
import { ErrorHttpActionTypes } from "../enums/ErrorHttpActionTypes.enum";
import { IErrorHttpProviderProps } from "../interfaces/IErrorHttpProviderProps.interface";
import { ErrorHttpState } from "../interfaces/IErrorHttpState.interface";
import { ErrorHttpAction } from "../types/ErrorHttpAction.type";

const errorInitialState: ErrorHttpState = {
  error: null,
  shouldLogout: false,
};

function errorHttpReducer(errorHttpState: ErrorHttpState = errorInitialState, action: ErrorHttpAction): ErrorHttpState {
  switch (action.type) {
    case ErrorHttpActionTypes.SET_ERROR:
      return {
        ...errorHttpState,
        error: action.payload.error,
        shouldLogout: action.payload.shouldLogout || false,
      };
    case ErrorHttpActionTypes.CLEAR_ERROR:
      return {
        ...errorHttpState,
        error: null,
        shouldLogout: false,
      };
    default:
      return errorHttpState;
  }
}

function ErrorHttpProvider({ children }: IErrorHttpProviderProps) {
  const [errorState, dispatch] = useReducer(errorHttpReducer, errorInitialState);

  const setError = useCallback(
    ({ error, shouldLogout }) => {
      dispatch({
        type: ErrorHttpActionTypes.SET_ERROR,
        payload: {
          error,
          shouldLogout,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [errorState.error]
  );

  const clearError = useCallback(
    () =>
      dispatch({
        type: ErrorHttpActionTypes.CLEAR_ERROR,
      }),
    []
  );

  const errorContextValue = useMemo(
    () => ({
      ...errorState,
      setError,
      clearError,
    }),
    [clearError, setError, errorState]
  );

  useLogoutWhenUnauthorized(errorState.shouldLogout, errorState.error);

  return <ErrorHttpContext.Provider value={errorContextValue}>{children}</ErrorHttpContext.Provider>;
}

export { ErrorHttpProvider };
