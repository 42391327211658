import { AI_CHATBOT_ACTIONS } from "./enums/chatbot.reducer.enum";
import { messagesReducer } from "./modules/chatbot-messages.reducer";
import { subscribtionReducer } from "./modules/chatbot-subscription.reducer";
import { flagsReducer } from "./modules/flags-reducer.reducer";
import { ChatbotAction, ChatbotState } from "./types/chatbot.reducer.type";

export const chatbotInitialState: ChatbotState = {
  messages: [],
  isSending: false,
  showEmptyState: false,
  scrollToBottom: false,
  showScrollToBottom: false,
  isCreatingNewSession: false,
  canCloneFromPreviousChat: false,
};

function baseChatbotReducer(action: ChatbotAction) {
  switch (action.type) {
    case AI_CHATBOT_ACTIONS.RESET:
      return {
        ...chatbotInitialState,
      };

    default:
      return {};
  }
}

export function chatbotReducer(state: ChatbotState, action: ChatbotAction): ChatbotState {
  const messagesState = messagesReducer(state, action);
  const flagsState = flagsReducer(state, action);
  const subscriptionState = subscribtionReducer(state, action);

  const baseChatbotState = baseChatbotReducer(action);

  const combinedReducers = {
    ...state,
    ...messagesState,
    ...flagsState,
    ...subscriptionState,
    ...baseChatbotState,
  };

  return combinedReducers;
}
