import { IJWTUserPayload } from "../../../interfaces/JWTUserPayload";
import { AuthenticationDataSource } from "../datasources/auth/authentication.datasource";
import { IAuthenticationDataSource } from "../datasources/auth/authentication.datasource.interface";
import { LocalAuthenticationStorageDataSource } from "../datasources/local-auth-storage/local-authentication-storage.datasource";
import { ILocalAuthenticationStorageDataSource } from "../datasources/local-auth-storage/local-authentication-storage.datasource.interface";
import { IAuthenticationRepository } from "./authentication.repository.interface";

export class AuthenticationRepository implements IAuthenticationRepository {
  constructor(
    private readonly _authDataSource: IAuthenticationDataSource = new AuthenticationDataSource(),
    private readonly _localAuthStorageDataSource: ILocalAuthenticationStorageDataSource = new LocalAuthenticationStorageDataSource()
  ) {}

  async logout(): Promise<void> {
    await this._authDataSource.logout();
  }

  resetAuthenticationStorage(): void {
    this._localAuthStorageDataSource.resetAuthenticationStorage();
  }

  emitAuthenticationTokenStore(accessToken: IJWTUserPayload | undefined): void {
    this._localAuthStorageDataSource.emitAuthenticationTokenStore(accessToken);
  }
}
