import { PropsWithChildren, useEffect, useState } from "react";
import BonusXLoading from "../../../components/BonusXLoading";
import { useClientConstantsReplacements } from "../../../hooks/use-client-constants/use-client-constants-replacements/use-client-constants-replacements";
import { clientConstantsServiceInstance } from "../client-constants.service.instance";

export function ClientConstantsProvider({ children }: Readonly<PropsWithChildren<{}>>) {
  const [isLoading, setIsLoading] = useState(true);
  const { revalidateReplacements } = useClientConstantsReplacements();

  useEffect(function initializeClientConstantsService() {
    async function initService() {
      await clientConstantsServiceInstance.initialize();
      revalidateReplacements();
      setIsLoading(false);
    }

    initService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return <BonusXLoading />;
  }

  return <>{children}</>;
}
