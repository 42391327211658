import cookie from "js-cookie";
import jwt_decode from "jwt-decode";
import { BONUSX_ACCESS_TOKEN } from "../../../../../common/env";
import { IJWTUserPayload } from "../../../../../interfaces/JWTUserPayload";
import { getCookie } from "../../../../../utils/getCookie";

export function getDecodedUserAccessTokenFromBrowserCookies(): IJWTUserPayload | undefined {
  const cookieToken = getCookie(BONUSX_ACCESS_TOKEN);

  if (!cookieToken) {
    return;
  }

  try {
    const decodedAuthenticationPayload = decodeJwt(cookieToken);
    return decodedAuthenticationPayload;
  } catch (error) {
    cookie.remove(BONUSX_ACCESS_TOKEN);
    console.error("Failed to decode JWT Token");
  }
}

function decodeJwt(jwtString: string): IJWTUserPayload {
  return jwt_decode<IJWTUserPayload>(jwtString);
}
