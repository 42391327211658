import { IChatbotMessage } from "../../../entities/chatbot-message.entity";
import { ConversationRepository } from "../../../repositories/conversation/conversation.repository";
import { IConversationRepository } from "../../../repositories/conversation/conversation.repository.interface";

export class ChatbotSessionService {
  constructor(private readonly _conversationRepository: IConversationRepository = new ConversationRepository()) {}

  public async createNewSession(): Promise<boolean> {
    try {
      await this._conversationRepository.createNewSession();
      return true;
    } catch (error) {
      console.error("Error creating new session:", error);
      return false;
    }
  }

  public async getActiveSessionMessages(): Promise<IChatbotMessage[]> {
    try {
      const lastSession = await this._conversationRepository.getLastSession();
      if (!lastSession?.isActive) {
        return [];
      }

      const oldMessages = await this._conversationRepository.getAllMessagesForSession(lastSession.id);
      return oldMessages;
    } catch (error) {
      console.error("Error getting active session messages:", error);
      return [];
    }
  }

  public async hasPreviousConversations(): Promise<boolean> {
    const conversations = await this._conversationRepository.getAllConversations();
    const hasPreviousConversations = conversations.length > 0;

    return hasPreviousConversations;
  }

  public async cloneFromPreviousChat(): Promise<boolean> {
    try {
      await this._conversationRepository.cloneFromPreviousChat();
      return true;
    } catch (error) {
      console.error("Error cloning from previous chat:", error);
      return false;
    }
  }
}
