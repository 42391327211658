import { ServiceTypeEnum } from "bonusx-api-main-manager";
import { Expose } from "class-transformer";
import { ProductCmsIseeRequirement } from "../../../services/ApiClientBonusxCmsRepository/enums/ProductCmsIseeRequirement.enum";
import { ProductCmsStatus } from "../../../services/ApiClientBonusxCmsRepository/enums/ProductCmsStatus.enum";
import { ProductCmsType } from "../../../services/ApiClientBonusxCmsRepository/enums/ProductCmsType.enum";
import { IProductDto } from "./product.dto.interface";

export class ProductDto implements IProductDto {
  @Expose({ name: "price" })
  price!: number;

  @Expose({ name: "discountedPrice" })
  discountedPrice!: number;

  @Expose({ name: "appliedDiscountCode" })
  appliedDiscountCode!: string;

  @Expose({ name: "shortDescription" })
  shortDescription!: string;

  @Expose({ name: "available" })
  available!: boolean;

  @Expose({ name: "type" })
  type!: ServiceTypeEnum;

  @Expose({ name: "subType" })
  subType!: ProductCmsType;

  @Expose({ name: "name" })
  name!: string;

  @Expose({ name: "labels" })
  labels!: string[];

  @Expose({ name: "id" })
  id!: number;

  @Expose({ name: "uniqueIdentifier" })
  uniqueIdentifier!: string;

  @Expose({ name: "requiredIsee" })
  requiredIsee!: ProductCmsIseeRequirement;

  @Expose({ name: "status" })
  status!: ProductCmsStatus;

  @Expose({ name: "category" })
  category!: number;

  @Expose({ name: "visibleFromTier" })
  visibleFromTier!: string;

  @Expose({ name: "requestableFromTier" })
  requestableFromTier!: string;

  @Expose({ name: "isRequestable" })
  isRequestable?: boolean;

  @Expose({ name: "isVisible" })
  isVisible?: boolean;

  @Expose({ name: "manageWithBonusx" })
  manageWithBonusx?: boolean;

  constructor(data?: IProductDto) {
    if (data) {
      this.price = data.price;
      this.discountedPrice = data.discountedPrice;
      this.appliedDiscountCode = data.appliedDiscountCode;
      this.shortDescription = data.shortDescription;
      this.available = data.available;
      this.type = data.type;
      this.subType = data.subType;
      this.name = data.name;
      this.labels = data.labels;
      this.id = data.id;
      this.uniqueIdentifier = data.uniqueIdentifier;
      this.requiredIsee = data.requiredIsee;
      this.status = data.status;
      this.category = data.category;
      this.visibleFromTier = data.visibleFromTier;
      this.requestableFromTier = data.requestableFromTier;
      this.isRequestable = data.isRequestable;
      this.isVisible = data.isVisible
      this.manageWithBonusx = data.manageWithBonusx;
    }
  }
}
