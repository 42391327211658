import { AxiosRequestConfig, AxiosResponse } from "axios";

export interface IApiClientResponse<Data> extends AxiosResponse<Data> {
  data: Data;
  status: number;
  statusText: string;
  headers: Record<string, unknown>;
  request?: Request;
}

export class AxiosApiClientResponse<Data> implements IApiClientResponse<Data> {
  public data: Data;
  public status: number;
  public statusText: string;
  public headers: Record<string, unknown>;
  public request?: Request;
  public config: AxiosRequestConfig;

  constructor(private _response: AxiosResponse<Data>) {
    this.data = this._response.data;
    this.status = this._response.status;
    this.statusText = this._response.statusText;
    this.headers = this._response.headers;
    this.request = this._response?.request;
    this.config = this._response.config;
  }
}
