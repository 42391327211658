import { AI_CHATBOT_ACTIONS } from "../enums/chatbot.reducer.enum";
import { ChatbotAction, ChatbotState } from "../types/chatbot.reducer.type";

export function flagsReducer(state: ChatbotState, action: ChatbotAction) {
  switch (action.type) {
    case AI_CHATBOT_ACTIONS.SET_IS_SENDING_ACTION:
      return {
        ...state,
        isSending: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_SHOW_EMPTY_STATE_ACTION:
      return {
        ...state,
        showEmptyState: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_SCROLL_TO_BOTTOM_ACTION:
      return {
        ...state,
        scrollToBottom: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_SHOW_SCROLL_TO_BOTTOM_BUTTON_ACTION:
      return {
        ...state,
        showScrollToBottom: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_IS_STREAMING_RESPONSE:
      return {
        ...state,
        isStreamingResponse: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_IS_CREATING_NEW_SESSION:
      return {
        ...state,
        isCreatingNewSession: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_CAN_CLONE_FROM_PREVIOUS_CHAT:
      return {
        ...state,
        canCloneFromPreviousChat: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_IS_CLONING_FROM_PREVIOUS_CHAT:
      return {
        ...state,
        isCloningFromPreviousChat: action.booleanPayloadValue,
      };
    case AI_CHATBOT_ACTIONS.SET_IS_AVAILABLE_IN_CURRENT_SUBSCRIPTION:
      return {
        ...state,
        isAvailableInCurrentSubscription: action.booleanPayloadValue,
      };

    case AI_CHATBOT_ACTIONS.SET_ERROR_WHILE_GENERATING_RESPONSE:
      return {
        ...state,
        errorWhileGeneratingResponse: action.booleanPayloadValue,
      };

    default:
      return {};
  }
}
