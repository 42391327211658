import { getBonusxAdminApiConfigV2 } from "../../../../common/bonusxApiConfig";
import { Mapper } from "../../../../utils/mapper";
import { IApiClientService } from "../../../ApiClient/ApiClientService.interface";
import AxiosApiClientInstance from "../../../AxiosApiClientInstance/AxiosApiClientInstance";
import { UserSubscriptionDto } from "../../dtos/user-subscription/user-subscription.dto";
import { ISpecificUserSubscriptionDatasource } from "./specific-user-subscription.datasource.interface";

export class SpecificUserSubscriptionDataSource implements ISpecificUserSubscriptionDatasource {
  private readonly _OPTIONS = getBonusxAdminApiConfigV2();
  private readonly _BASE_URL = "/subscriptions";

  constructor(private readonly _client: IApiClientService = new AxiosApiClientInstance()) {}

  async getSpecificUserSubscription(citizenId: number): Promise<UserSubscriptionDto> {
    const { data } = await this._client.get(`${this._BASE_URL}/account?citizenId=${citizenId}`, this._OPTIONS);

    if (!data) {
      throw new Error("Specific user subscription not available");
    }

    const mappedDto = Mapper.mapData(UserSubscriptionDto, data);

    return mappedDto;
  }
}
