import initial from "lodash/initial";
import last from "lodash/last";
import { IChatbotMessage } from "../../../entities/chatbot-message.entity";

export function appendStreamChunkToAiResponse(
  messages: IChatbotMessage[],
  newChunk: IChatbotMessage
): IChatbotMessage[] {
  if (messages.length === 0) {
    return [newChunk];
  }

  const messagesWithoutLastMessage = initial(messages);
  const lastMessage = last(messages);

  if (!lastMessage) {
    return [...messagesWithoutLastMessage, newChunk];
  }

  const updatedLastMessage: IChatbotMessage = {
    ...lastMessage,
    text: lastMessage.text + newChunk.text,
  };

  return [...messagesWithoutLastMessage, updatedLastMessage];
}
