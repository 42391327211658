import { ClientConstantsService } from "../../../../services/client-constants/client-constants.service";
import { ReplaceSinglePlaceholderProps } from "../../../../services/client-constants/client-constants.service.interface";
import { BONUSX_SUBSCRIPTION_TIERS } from "../../../../services/user-subscription/enums/bonusx-subscription-tiers.enum";
import { getTranslationKeyForSubscriptionTier } from "../../../use-current-user-subscription-tier/use-current-user-subscription-tier.functions";
import { CLIENT_CONSTANTS_PLACEHOLDERS } from "./client-constants-placeholders.enum";
import { GetReplacementsProps, IClientConstantsReplacementsManager } from "./replacements-manager.interface";

export class ClientConstantsReplacementsManager implements IClientConstantsReplacementsManager {
  constructor(private readonly _clientConstantsService: ClientConstantsService = ClientConstantsService.instance) {}

  getReplacements({
    chatbotName,
    userFirstName,
    currentUserTier,
    translationKeyForTier,
  }: Readonly<GetReplacementsProps>): ReplaceSinglePlaceholderProps[] {
    return [
      this._getChatbotNameReplacement(chatbotName),
      this._getUserFirstNameReplacement(userFirstName),
      this._getCurrentUserSubscriptionTierReplacement({ currentUserTier, translationKeyForTier }),
      ...this._getSubscriptionTiersReplacements(),
    ];
  }

  private _getChatbotNameReplacement(chatbotName: string): ReplaceSinglePlaceholderProps {
    return {
      stringToReplace: CLIENT_CONSTANTS_PLACEHOLDERS.assistantName,
      replaceWith: chatbotName,
    };
  }

  private _getUserFirstNameReplacement(userFirstName: string): ReplaceSinglePlaceholderProps {
    return {
      stringToReplace: CLIENT_CONSTANTS_PLACEHOLDERS.userFirstName,
      replaceWith: userFirstName,
    };
  }

  private _getCurrentUserSubscriptionTierReplacement({
    currentUserTier,
    translationKeyForTier,
  }: Pick<GetReplacementsProps, "currentUserTier" | "translationKeyForTier">): ReplaceSinglePlaceholderProps {
    const translatedSubscriptionTier = this._clientConstantsService.getClientConstantValueOrDefault({
      key: translationKeyForTier,
      default: currentUserTier,
    });

    return {
      stringToReplace: CLIENT_CONSTANTS_PLACEHOLDERS.currentUserSubscriptionTier,
      replaceWith: translatedSubscriptionTier,
    };
  }

  private _getSubscriptionTiersReplacements(): ReplaceSinglePlaceholderProps[] {
    const tiers = [
      { placeholder: CLIENT_CONSTANTS_PLACEHOLDERS.subscriptionFree, tier: BONUSX_SUBSCRIPTION_TIERS.FREE },
      { placeholder: CLIENT_CONSTANTS_PLACEHOLDERS.subscriptionTier1, tier: BONUSX_SUBSCRIPTION_TIERS.TIER_1 },
      { placeholder: CLIENT_CONSTANTS_PLACEHOLDERS.subscriptionTier2, tier: BONUSX_SUBSCRIPTION_TIERS.TIER_2 },
      { placeholder: CLIENT_CONSTANTS_PLACEHOLDERS.subscriptionTier3, tier: BONUSX_SUBSCRIPTION_TIERS.TIER_3 },
    ];

    return tiers.map(({ placeholder, tier }) => ({
      stringToReplace: placeholder,
      replaceWith: this._clientConstantsService.getClientConstantValueOrDefault({
        key: getTranslationKeyForSubscriptionTier(tier),
        default: tier,
      }),
    }));
  }
}
