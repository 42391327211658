import { useAtomValue } from "jotai";
import { accessTokenStore } from "../../services/authentication-service/stores/access-token/access-token.store";

export function useIsLogged() {
  const accessToken = useAtomValue(accessTokenStore);

  if (!accessToken) {
    return false;
  }

  if (!accessToken.exp) {
    return true;
  }

  const currentTimeInMilliseconds = Date.now();
  const accessTokenExpirationInMilliseconds = accessToken?.exp * 1000;
  const isTokenExpired = accessTokenExpirationInMilliseconds < currentTimeInMilliseconds;
  return !isTokenExpired;
}
